import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/es";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

//componentes
import "./becados.css";
import Alert from "../../modal/alert";

class CitasAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      inventario: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: true,
      xtotales: "",
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      iterapeuta: [],
      terapeuta: "",
      iasociarD: [],
      iasociarD1: [],
      iasociarD2: [],
      iasociarD3: [],
      becado: "NO",
      incluirdatosOn: false,
      alumno: "",
      horaS: "",
      fechaT: moment().format("DD-MM-YYYY"),
      facturacionOn: false,
      semiBecasOn: false,
      cxcAutorizadosOn: false,
      becasOn: false,
      idproducto: "",
      precio: "",
      iinformeFechasS: [],
      iinformeFechas: [],
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      terapeuta: "",
      iasociarD: [],
      iasociarD1: [],
      iasociarD2: [],
      iasociarD3: [],
      becado: "NO",
      incluirdatosOn: false,
      alumno: "",
      horaS: "",
      fechaT: moment().format("DD-MM-YYYY"),
      verplanillaOn: true,
      facturacionOn: false,
      semiBecasOn: false,
      cxcAutorizadosOn: false,
      idproducto: "",
      precio: "",
      iinformeFechasS: [],
      iinformeFechas: [],
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });

    // if (e.target.name === "casos") {
    //   this.terapeutasDisponibles(e.target.value);
    // }
    // if (e.target.name === "medicos") {
    //   this.consultarHorarioEspecialista(e.target.value);
    // }
    // if (e.target.name === "tipoFecha") {
    //   if (e.target.value === "2") this.valorInicial(e.target.value);
    // }
  }
  onChange1(e) {
    // console.log(this.state.nana)
    // console.log(e.target.checked)
    if (e.target.checked) {
      this.setState({
        becado: "SI",
      });
    } else {
      this.setState({
        becado: "NO",
      });
    }
  }
  onChange4(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.consultarProducto(e.target.value);
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }

  // terapeutasDisponibles = (e) => {
  //   let data = new FormData();
  //   data.append("idespecialidad", e);
  //   data.append("boton", "consultaTerapeutasEspecialista");

  //   let config = {
  //     headers: {
  //       "Content-Type": "text/html",
  //     },
  //   };
  //   const xurl =
  //     "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
  //   axios
  //     .post(xurl, data, config)

  //     .then((res) => {
  //       let ximedicos = res.data.variable2;
  //       if (res.data.variable1) {
  //         this.setState({
  //           medicos: "",
  //           imedicos: [],
  //           imedicos: ximedicos,
  //         });
  //       } else {
  //         this.setState({
  //           medicos: "",
  //           imedicos: [],
  //         });
  //       }
  //     });
  // };

  imprimir = () => {
    let data = new FormData();
    data.append("cedulaTerapeuta", this.state.terapeuta);
    data.append("arregloPaciente", JSON.stringify(this.state.iinformeFechasS));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteHorarioEspecialista.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  eliminar4 = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "eliminarAutorizadosCxC");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "El Item Autorizado de CxC Fue Eliminado con Exito",
        });
        this.buscarBecado(this.state.cedulaAlumno);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "Ocurrio un Error, Verifique por Favor",
        });
      }
    });
  };

  eliminar3 = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "eliminarproductoB");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "El Item Fue Eliminado con Exito",
        });
        this.buscarBecado(this.state.cedulaAlumno);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "Ocurrio un Error, Verifique por Favor",
        });
      }
    });
  };

  eliminar2 = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "eliminarproductoSB");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "El Item Fue Eliminado con Exito",
        });
        this.buscarBecado(this.state.cedulaAlumno);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "Ocurrio un Error, Verifique por Favor",
        });
      }
    });
  };

  eliminar = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("boton", "eliminarproducto");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "El Item Fue Eliminado con Exito",
        });
        this.buscarBecado(this.state.cedulaAlumno);
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Eliminar Item al Paciente.",
          cuerpoM: "Ocurrio un Error, Verifique por Favor",
        });
      }
    });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarBecado = (e) => {
    let data = new FormData();
    data.append("cedulaPaciente", e);
    data.append("boton", "buscarBecados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiasociarD3 = res.data.variable6;
        let xiasociarD2 = res.data.variable5;
        let xiasociarD1 = res.data.variable4;
        let xiasociarD = res.data.variable3;
        let xbecado = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iasociarD3: xiasociarD3,
            iasociarD2: xiasociarD2,
            iasociarD1: xiasociarD1,
            iasociarD: xiasociarD,
            becado: xbecado,
          });
        } else {
          this.setState({ iasociarD3: xiasociarD3 });
        }
      });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (
              res.data.variable129 === "MADRE" ||
              res.data.variable129 === "PADRE" ||
              res.data.variable129 === "OTRO"
            ) {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4)
              this.buscarBecado(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4)
              this.buscarBecado(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133);
          if (res.data.variable136) {
            if (
              res.data.variable129 === "MADRE" ||
              res.data.variable129 === "PADRE" ||
              res.data.variable129 === "OTRO"
            ) {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
                incluirdatosOn: true,
              });
              this.buscarIT(res.data.variable4)
              this.buscarBecado(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
                verplanillaOn: false,
              });
              this.buscarIT(res.data.variable4)
              this.buscarBecado(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };
  cerrarLista2 = () => {
    //console.log(this.state.listaOn)
    if (this.state.verplanillaOn) {
      this.setState({
        verplanillaOn: false,
      });
    }
    if (!this.state.verplanillaOn) {
      this.setState({
        verplanillaOn: true,
      });
    }
  };
  incluirdatos = () => {
    //console.log(this.state.listaOn)
    if (this.state.incluirdatosOn) {
      this.setState({
        incluirdatosOn: false,
      });
    }
    if (!this.state.incluirdatosOn) {
      this.setState({
        incluirdatosOn: true,
      });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  buscarE = () => {
    let data = new FormData();
    data.append("boton", "Buscar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/medicos.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);

        let xterapeuta = res.data;
        this.setState({
          iterapeuta: xterapeuta,
        });
      });
  };

  asociarEvaluacion = () => {
    let data = new FormData();
    data.append("boton", "Consultar");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maestroCasosPHP.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xcasos = res.data;
        this.setState({
          icasos: xcasos,
        });
      });
  };

  consultarPaciente = (e) => {
    if (e !== "") {
      console.log("tiene datos " + e);
    } else {
      this.setState({
        verplanillaOn: true,
      });
    }
  };

  guardarCita = () => {
    let data = new FormData();
    data.append("cedulaPaciente", this.state.cedulaAlumno);
    data.append("becado", this.state.becado);
    data.append("idinventario", this.state.idproducto);
    data.append("boton", "agregarBecados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Guardar Pacientes Becados.",
            cuerpoM: "El Registro SE Actualizo de forma Correcta.",
          });
          this.buscarBecado(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Guardar Pacientes Becados.",
            cuerpoM: "Ya existe el Iten Becado, Verifique Por Favor.",
          });
        }
      });
  };

  facturarBecados = (e) => {
    if (this.state.facturacionOn) {
      this.setState({
        facturacionOn: false,
      });
    } else {
      this.setState({
        facturacionOn: true,
      });
    }
  };
  Becados = (e) => {
    if (this.state.becasOn) {
      this.setState({
        becasOn: false,
      });
    } else {
      this.buscarIT();
      this.setState({
        becasOn: true,
      });
    }
  };
  semiBecados = (e) => {
    if (this.state.semiBecasOn) {
      this.setState({
        semiBecasOn: false,
      });
    } else {
      this.buscarIT();
      this.setState({
        semiBecasOn: true,
      });
    }
  };

  cxcAutorizados = (e) => {
    if (this.state.cxcAutorizadosOn) {
      this.setState({
        cxcAutorizadosOn: false,
      });
    } else {
      this.buscarIT();
      this.setState({
        cxcAutorizadosOn: true,
      });
    }
  };

  consultarInventario = () => {
    let data = new FormData();
    data.append("boton", "consultarInventario");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          //this.consultarInventario()
          let xrutas = res.data.variable2;
          this.setState({
            inventario: xrutas,
          });
        }
      });
    this.setState({
      loading: false,
    });
  };

  consultarProducto = (e) => {
    let data = new FormData();
    data.append("idinventario", e);
    data.append("cedulaPaciente", this.state.cedulaAlumno);
    data.append("boton", "consultaInventarioIndividual");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/inventario.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.setState({
            idproducto: res.data.variable2,
            nombrepro: res.data.variable3,
            cantidadActual: res.data.variable4,
            costo: res.data.variable5,
            precio: res.data.variable6,
            impuesto: res.data.variable8,
            cantidad: "1",
          });
        }
      });
  };
  facturarIten = () => {
    let data = new FormData();
    data.append("idinventario", this.state.idproducto);
    data.append("precio", this.state.precio);
    data.append("cedulaPaciente", this.state.cedulaAlumno);
    data.append("boton", "guardarIten");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarBecado(this.state.cedulaAlumno);
          this.setState({
            idproducto: "",
            precio: "",
            facturacionOn: false,
          });
        }
      });
  };

  facturarcxcAutorizados = () => {
    let data = new FormData();
    data.append("idinventario", this.state.idproducto);
    data.append("cantidad", this.state.cantidad);
    data.append("cedulaPaciente", this.state.cedulaAlumno);
    data.append("boton", "guardarItenCxC");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarBecado(this.state.cedulaAlumno);
          this.setState({
            idproducto: "",
            precio: "",
            cxcAutorizadosOn: false,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Guardar Item Autorizados CxC.",
            cuerpoM: "El Registro Ya Existe, Verifique Por Favor.",
          });
        }
      });
  };

  facturarItenSBecados = () => {
    let data = new FormData();
    data.append("idinventario", this.state.idproducto);
    data.append("cantidad", this.state.cantidad);
    data.append("precio", this.state.precio);
    data.append("cedulaPaciente", this.state.cedulaAlumno);
    data.append("boton", "guardarItenSBecados");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/becado.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        if (res.data.variable1) {
          this.buscarBecado(this.state.cedulaAlumno);
          this.setState({
            idproducto: "",
            precio: "",
            semiBecasOn: false,
          });
        }
      });
  };

  buscarIT = (e) => {
    let data = new FormData();
    console.log('e '+e)
    if (e !== '' && e !== undefined){
      data.append("cedulaAlumno", e);
    }else{
      data.append("cedulaAlumno", this.state.cedulaAlumno);
    }
    
    data.append("boton", "consultarInforme");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            iinformeFechasS: xiinformeFechasS,
            iinformeFechas: xiinformeFechas,
          });
        } else {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
          });
        }
      });
  };

  componentDidMount() {
    this.buscarE();
    this.asociarEvaluacion();
    this.consultarInventario();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>MAESTRO DE PACIENTES BECADOS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {this.state.verplanillaOn && (
            <div className="cont-tablamedi row">
              <div className="tablamedi1 col-12">
                <div className="contenedorp">
                  <div className="titulo-planillai col-12">
                    <p>
                      SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                      CORRESPONDIENTA A SU SELECCIÓN.{" "}
                    </p>
                  </div>
                  <div className="row">
                    <div className="fechas col-12">
                      <select
                        id="tbusqueda"
                        name="tbusqueda"
                        value={this.state.tbusqueda}
                        onChange={this.onChange.bind(this)}
                      >
                        <option>-- Buscar por --</option>
                        <option value="1">CEDULA/PASAPORTE</option>
                        <option value="2">NOMBRE PACIENTE</option>
                        {/* <option value="3">CURSO/GRADO</option>
                    <option value="4">TODOS LOS PACIENTES</option> */}
                      </select>
                    </div>
                  </div>

                  {this.state.tbusqueda < "3" && (
                    <div className="contenedorcedula1 row">
                      <input
                        className="form-control"
                        type="text"
                        name="identificacionp1"
                        id="identificacionp1"
                        autoComplete="off"
                        placeholder={
                          this.state.tbusqueda === "1"
                            ? "Identificación Paciente."
                            : "Nombre Paciente."
                        }
                        maxLength="300"
                        onChange={this.onChange.bind(this)}
                        value={this.state.identificacionp1.toUpperCase()}
                      />
                    </div>
                  )}
                  <div className="xcontenedorcedula1 col-12">
                    <button
                      className="boton"
                      onClick={this.buscarPlanilla.bind(this)}
                    >
                      Consultar
                    </button>
                    <button className="boton" onClick={this.limpiar.bind(this)}>
                      Otra Consulta
                    </button>

                    <Link to="/">
                      <button className="boton">Salir</button>
                    </Link>
                  </div>

                  {this.state.listaOn && (
                    <div className="cont-tablamedi row">
                      <div className="tablamedi1 col-11">
                        <div className="cont-titulomedi col-12">
                          <span className="titulocasos">
                            Listado de Pacientes
                          </span>
                          <span
                            className="titulo-cerrarcasos"
                            onClick={this.cerrarLista.bind(this)}
                          >
                            X
                          </span>
                        </div>
                        {/* <span className="titulo-casos">Datos del Pago</span> */}
                        <div className="cont-listmedi row">
                          <div className="listmedi col-12">
                            <table className="tablame" border="1">
                              <tbody className="evocuerpo1">
                                {this.state.nalumno.map((item, i) => {
                                  return (
                                    <tr
                                      className={i % 2 === 0 ? "odd" : ""}
                                      key={item.variable1}
                                      id="tabla"
                                    >
                                      <td
                                        className="renglon"
                                        width="10"
                                        onClick={this.consulta.bind(
                                          this,
                                          item.variable1
                                        )}
                                      >
                                        {item.variable2}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {this.state.semiBecasOn === true && (
            <div className="flotador3xfB">
              <div className="flotador1fB">
                <div className="row">
                  {this.state.iinformeFechasS.map((item, i) => {
                    return (
                      <div className="cont-diasterapia">
                        {item.variable11 === "0" && (
                          <div className="cont-diasterapia2 row">
                            <div className="diasNombre col-12">
                              PACIENTE: {item.variable2}
                            </div>
                            <div className="diasHa">HORARIO</div>
                            <div className="diasS">LUNES</div>
                            <div className="diasS">MARTES</div>
                            <div className="diasS">MIERCOLES</div>
                            <div className="diasS">JUEVES</div>
                            <div className="diasS">VIERNES</div>
                            <div className="diasS">SABADO</div>
                            <div className="diasS">DOMINGO</div>
                          </div>
                        )}
                        {item.variable11 !== "1" && (
                          <div className="group row">
                            <div className="XdiasH">{item.variable3}</div>
                            <div className="XdiasS">{item.variable4}</div>
                            <div className="XdiasS">{item.variable5}</div>
                            <div className="XdiasS">{item.variable6}</div>
                            <div className="XdiasS">{item.variable7}</div>
                            <div className="XdiasS">{item.variable8}</div>
                            <div className="XdiasS">{item.variable9}</div>
                            <div className="XdiasS">{item.variable10}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    <p>Item Inventarios Semi Becados.</p>
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      <div className="contenedorcedula1f1 col-4">
                        <select
                          id="idproducto"
                          name="idproducto"
                          value={this.state.idproducto}
                          onChange={this.onChange4.bind(this)}
                        >
                          <option>-- Seleccionar Servicios --</option>

                          {this.state.inventario.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="cantidad"
                          id="cantidad"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder="Cantidad."
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.cantidad}
                        />
                      </div>
                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="precio"
                          id="precio"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder="Precio."
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.precio}
                        />
                      </div>
                    </div>
                    <div className="cont-totales">
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.facturarItenSBecados.bind(this)}
                      >
                        Agregar Items
                      </button>

                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.semiBecados.bind(this)}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.facturacionOn === true && (
            <div className="flotador3xfB">
              <div className="flotador1fB">
                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    <p>Item Inventarios Becados.</p>
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      <div className="contenedorcedula1f1 col-4">
                        <select
                          id="idproducto"
                          name="idproducto"
                          value={this.state.idproducto}
                          onChange={this.onChange4.bind(this)}
                        >
                          <option>-- Seleccionar Servicios --</option>

                          {this.state.inventario.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="precio"
                          id="precio"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder="Precio."
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.precio}
                        />
                      </div>
                    </div>
                    <div className="cont-totales">
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.facturarIten.bind(this)}
                      >
                        Agregar Items
                      </button>

                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.facturarBecados.bind(this)}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.becasOn === true && (
            <div className="flotador3xfB">
              <div className="flotador1fB">
                <div className="row">
                  {this.state.iinformeFechasS.map((item, i) => {
                    return (
                      <div className="cont-diasterapia">
                        {item.variable11 === "0" && (
                          <div className="cont-diasterapia2 row">
                            <div className="diasNombre col-12">
                              PACIENTE: {item.variable2}
                            </div>
                            <div className="diasHa">HORARIO</div>
                            <div className="diasS">LUNES</div>
                            <div className="diasS">MARTES</div>
                            <div className="diasS">MIERCOLES</div>
                            <div className="diasS">JUEVES</div>
                            <div className="diasS">VIERNES</div>
                            <div className="diasS">SABADO</div>
                            <div className="diasS">DOMINGO</div>
                          </div>
                        )}
                        {item.variable11 !== "1" && (
                          <div className="group row">
                            <div className="XdiasH">{item.variable3}</div>
                            <div className="XdiasS">{item.variable4}</div>
                            <div className="XdiasS">{item.variable5}</div>
                            <div className="XdiasS">{item.variable6}</div>
                            <div className="XdiasS">{item.variable7}</div>
                            <div className="XdiasS">{item.variable8}</div>
                            <div className="XdiasS">{item.variable9}</div>
                            <div className="XdiasS">{item.variable10}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    <p>Item Inventarios Becados.</p>
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      <div className="contenedorcedula1f1 col-4">
                        <select
                          id="idproducto"
                          name="idproducto"
                          value={this.state.idproducto}
                          onChange={this.onChange4.bind(this)}
                        >
                          <option>-- Seleccionar Servicios --</option>

                          {this.state.inventario.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {/* <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="cantidad"
                          id="cantidad"
                          
                          autoComplete="off"
                          placeholder="Cantidad."
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.cantidad}
                        />
                      </div> */}
                      {/* <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="precio"
                          id="precio"
                          
                          autoComplete="off"
                          placeholder="Precio."
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.precio}
                        />
                      </div> */}
                    </div>
                    <div className="cont-totales">
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.guardarCita.bind(this)}
                      >
                        Agregar Items Becados
                      </button>

                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.Becados.bind(this)}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.cxcAutorizadosOn === true && (
            <div className="flotador3xfB">
              <div className="flotador1fB">
                <div className="row">
                  {this.state.iinformeFechasS.map((item, i) => {
                    return (
                      <div className="cont-diasterapia">
                        {item.variable11 === "0" && (
                          <div className="cont-diasterapia2 row">
                            <div className="diasNombre col-12">
                              PACIENTE: {item.variable2}
                            </div>
                            <div className="diasHa">HORARIO</div>
                            <div className="diasS">LUNES</div>
                            <div className="diasS">MARTES</div>
                            <div className="diasS">MIERCOLES</div>
                            <div className="diasS">JUEVES</div>
                            <div className="diasS">VIERNES</div>
                            <div className="diasS">SABADO</div>
                            <div className="diasS">DOMINGO</div>
                          </div>
                        )}
                        {item.variable11 !== "1" && (
                          <div className="group row">
                            <div className="XdiasH">{item.variable3}</div>
                            <div className="XdiasS">{item.variable4}</div>
                            <div className="XdiasS">{item.variable5}</div>
                            <div className="XdiasS">{item.variable6}</div>
                            <div className="XdiasS">{item.variable7}</div>
                            <div className="XdiasS">{item.variable8}</div>
                            <div className="XdiasS">{item.variable9}</div>
                            <div className="XdiasS">{item.variable10}</div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                <div className="cont-factura col-12">
                  <div className="titulo-cxc ">
                    <p>Item Inventarios Autorizado CxC.</p>
                  </div>
                  <div className="cont-campos">
                    <div className="campos-factura">
                      <div className="contenedorcedula1f1 col-4">
                        <select
                          id="idproducto"
                          name="idproducto"
                          value={this.state.idproducto}
                          onChange={this.onChange4.bind(this)}
                        >
                          <option>-- Seleccionar Servicios --</option>

                          {this.state.inventario.map((item, i) => {
                            return (
                              <option key={i} value={item.variable1}>
                                {item.variable2}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="contenedorcedula1f col-2">
                        <input
                          className="form-control"
                          type="text"
                          name="cantidad"
                          id="cantidad"
                          // readOnly="readonly"
                          autoComplete="off"
                          placeholder="Cantidad."
                          maxLength="7"
                          onChange={this.onChange.bind(this)}
                          value={this.state.cantidad}
                        />
                      </div>
                    </div>
                    <div className="cont-totales">
                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.facturarcxcAutorizados.bind(this)}
                      >
                        Agregar Items
                      </button>

                      <button
                        className={this.state.guardarOn ? "botonOf" : "boton"}
                        onClick={this.cxcAutorizados.bind(this)}
                      >
                        Salir
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.incluirdatosOn && (
            <div className="cont-listmedixB row">
              <div className="listcxcxB  col-12">
                <div className="contenedorpB">
                  <div className="titulo-planillai col-12">
                    <p>
                      {this.state.cedulaAlumno}
                      {" - "}
                      {this.state.alumno}
                    </p>
                  </div>
                  <div className="xcontenedorcedula1x col-12">
                    {/* {this.state.iasociarD.length === 0 && (
                      <div className="xcontenedorcedula1x col-2">
                        <label className="opcion2cB">Becado.</label>
                        <input
                          className="botonxB"
                          type="checkbox"
                          name="becado"
                          id="becado"
                          checked={this.state.becado === "SI"}
                          onChange={this.onChange1.bind(this)}
                        />
                      </div>
                    )} */}
                    <div className="xcontenedorcedula1x col-10">
                      {/* <button
                        className="boton"
                        onClick={this.guardarCita.bind(this)}
                      >
                        Guardar
                      </button> */}

                      <button
                        className="boton"
                        onClick={this.Becados.bind(this)}
                      >
                        Becados
                      </button>
                      {this.state.becado !== "SI" && (
                        <button
                          className="boton"
                          onClick={this.facturarBecados.bind(this)}
                        >
                          Cambio de Tarifa
                        </button>
                      )}
                      {this.state.becado !== "SI" && (
                        <button
                          className="boton"
                          onClick={this.semiBecados.bind(this)}
                        >
                          Semi Becados
                        </button>
                      )}
                      {this.state.becado !== "SI" && (
                        <button
                          className="boton"
                          onClick={this.cxcAutorizados.bind(this)}
                        >
                          Items Autorizados CxC
                        </button>
                      )}
                      <button
                        className="boton"
                        onClick={this.limpiar.bind(this)}
                      >
                        Otra Consulta
                      </button>

                      <Link to="/">
                        <button className="boton">Salir</button>
                      </Link>
                    </div>
                  </div>

                  {this.state.iinformeFechasS.length>0 && this.state.cxcAutorizadosOn === false && this.state.becasOn === false && this.state.semiBecasOn === false && this.state.facturacionOn === false && (
                 
                    <div className="row">
                      {this.state.iinformeFechasS.map((item, i) => {
                        return (
                          <div className="cont-diasterapia">
                            {item.variable11 === "0" && (
                              <div className="cont-diasterapia2 row">
                               
                                <div className="diasHa">HORARIO</div>
                                <div className="diasS">LUNES</div>
                                <div className="diasS">MARTES</div>
                                <div className="diasS">MIERCOLES</div>
                                <div className="diasS">JUEVES</div>
                                <div className="diasS">VIERNES</div>
                                <div className="diasS">SABADO</div>
                                <div className="diasS">DOMINGO</div>
                              </div>
                            )}
                            {item.variable11 !== "1" && (
                              <div className="group row">
                                <div className="XdiasH">{item.variable3}</div>
                                <div className="XdiasS">{item.variable4}</div>
                                <div className="XdiasS">{item.variable5}</div>
                                <div className="XdiasS">{item.variable6}</div>
                                <div className="XdiasS">{item.variable7}</div>
                                <div className="XdiasS">{item.variable8}</div>
                                <div className="XdiasS">{item.variable9}</div>
                                <div className="XdiasS">{item.variable10}</div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}

                  {this.state.iasociarD3.length > 0 && (
                    // <div className="row">
                    <div className="cont-listmedixB row">
                      <div className="listcxcxB col-12">
                        <table className="tablacxcx" border="1">
                          <thead>
                            <tr>
                              <th className="titucxc1B">I.d.</th>
                              <th className="titucxc1B">
                                Producto Autorizados CXC
                              </th>
                              <th className="titucxc1B">Cantidad</th>
                              <th className="titucxc3xpe">
                                <span className="titulo-eliminar">
                                  <span className="cerrarme3"></span>
                                </span>
                              </th>
                            </tr>
                          </thead>

                          {this.state.iasociarD3.map((item, i) => {
                            return (
                              <tr
                                className={i % 2 === 0 ? "odd" : "odd"}
                                key={item.variable1}
                                id="tabla"
                              >
                                <td className="totalescxcx">
                                  {item.variable2}
                                </td>
                                <td className="totalescxcx">
                                  {item.variable3}
                                </td>
                                <td className="totalescxcx">
                                  {item.variable4}
                                </td>
                                <td className="xcerr">
                                  <span
                                    className="cerrarme1"
                                    onClick={this.eliminar4.bind(
                                      this,
                                      item.variable1
                                    )}
                                  ></span>
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  )}

                  {this.state.iasociarD2.length > 0 && (
                    <div className="row">
                      <div className="cont-listmedixB row">
                        <div className="listcxcxB col-12">
                          <table className="tablacxcx" border="1">
                            <thead>
                              <tr>
                                <th className="titucxc1B">I.d.</th>
                                <th className="titucxc1B">Producto Becados</th>
                                <th className="titucxc3xpe">
                                  <span className="titulo-eliminar">
                                    <span className="cerrarme3"></span>
                                  </span>
                                </th>
                              </tr>
                            </thead>

                            {this.state.iasociarD2.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : "odd"}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td className="totalescxcx">
                                    {item.variable2}
                                  </td>
                                  <td className="totalescxcx">
                                    {item.variable3}
                                  </td>
                                  <td className="xcerr">
                                    <span
                                      className="cerrarme1"
                                      onClick={this.eliminar3.bind(
                                        this,
                                        item.variable1
                                      )}
                                    ></span>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.iasociarD1.length > 0 && (
                    <div className="row">
                      <div className="cont-listmedixB row">
                        <div className="listcxcxB col-12">
                          <table className="tablacxcx" border="1">
                            <thead>
                              <tr>
                                <th className="titucxc1B">I.d.</th>
                                <th className="titucxc1B">
                                  Producto Semi-Becados
                                </th>
                                <th className="titucxc1B">Cantidad</th>
                                <th className="titucxc1B">Precio</th>
                                <th className="titucxc3xpe">
                                  <span className="titulo-eliminar">
                                    <span className="cerrarme3"></span>
                                  </span>
                                </th>
                              </tr>
                            </thead>

                            {this.state.iasociarD1.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : "odd"}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td className="totalescxcx">
                                    {item.variable2}
                                  </td>
                                  <td className="totalescxcx">
                                    {item.variable3}
                                  </td>
                                  <td className="totalescxcx">
                                    {item.variable4}
                                  </td>
                                  <td className="totalescxcx">
                                    {item.variable5}
                                  </td>

                                  <td className="xcerr">
                                    <span
                                      className="cerrarme1"
                                      onClick={this.eliminar2.bind(
                                        this,
                                        item.variable1
                                      )}
                                    ></span>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.iasociarD.length > 0 && (
                    <div className="row">
                      <div className="cont-listmedixB row">
                        <div className="listcxcxB col-12">
                          <table className="tablacxcx" border="1">
                            <thead>
                              <tr>
                                <th className="titucxc1B">I.d.</th>
                                <th className="titucxc1B">
                                  Producto Cambio de Precio
                                </th>
                                <th className="titucxc1B">Precio</th>
                                <th className="titucxc3xpe">
                                  <span className="titulo-eliminar">
                                    <span className="cerrarme3"></span>
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            {this.state.iasociarD.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : "odd"}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td className="totalescxcx">
                                    {item.variable2}
                                  </td>
                                  <td className="totalescxcx">
                                    {item.variable3}
                                  </td>
                                  <td className="totalescxcx">
                                    {item.variable4}
                                  </td>

                                  <td className="xcerr">
                                    <span
                                      className="cerrarme1"
                                      onClick={this.eliminar.bind(
                                        this,
                                        item.variable1
                                      )}
                                    ></span>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default CitasAction;
