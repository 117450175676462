import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";

//componentes
import "./consultaIT2.css";
import Alert from "../../modal/alert";

class SeccionesAAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idseccion: "",
      cedulaAlumno: "",
      idseccionA: "",
      trimestre: "",
      idmateria: "",
      anoEscolar: [moment().format("YYYY")],
      ianoEscolar: [],
      iseccionesA: [],
      iinasistencia: [],
      ialumnos: [],
      isecciones: [],
      imaterias: [],
      listaOn: false,
      elimi: false,
      verplanillaOn: false,
      xtotales: "",
      iinformeFechasS: [],
      iinformeFechas: [],
      iinformeFechasS2: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      remplazarOn: false,
      remplazarHOn: false,
      iespecialista: [],
      imedicos: [],
      ifechasConsultas: [],
      ihorarioNuevo: [],
      horarioNuevo: "",
      especialista: "",
      medicos: "",
      fechasConsultas: "",
      tieneHorario: false,
      diaCalendario: "",
      horaCalendario: "",
    };
  }

  limpiar = () => {
    this.setState({
      idseccionP: "",
      elimi: false,
      listaOn: false,
      iinformeFechasS: [],
      iinformeFechas: [],
      identificacionp1: "",
      tbusqueda: "",
      cedula: "",
      remplazarOn: false,
      remplazarHOn: false,
      iespecialista: [],
      imedicos: [],
      ifechasConsultas: [],
      ihorarioNuevo: [],
      horarioNuevo: "",
      especialista: "",
      medicos: "",
      fechasConsultas: "",
      tieneHorario: false,
      diaCalendario: "",
      horaCalendario: "",
    });
  };
  onChange(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "especialista") {
      this.horarioTerapeutasAsociados(e.target.value);
      this.terapeutasDisponibles();
    }
    // if (e.target.name === "fechasConsultas") {
    //   this.terapeutasDisponibles(e.target.value);
    // }
    if (e.target.name === "medicos") {
      this.buscarEspecialista(e.target.value);
    }
  }

  onChange5(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });

    if (e.target.name === "fechasConsultas") {
      this.buscarEspecialista(this.state.especialista);
    }
  }

  onChange2(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    this.buscarAlumnos(e.target.value);
    //this.buscarSeccionesA(e.target.value);
  }

  onChange3(e) {
    //console.log(e.target.name)
    this.setState({
      [e.target.name]: e.target.value,
    });
    //this.buscarAlumnos(e.target.value);
  }
  onChange4(e) {
    let index = e.target.name;
    //  console.log(index);
    // console.log("P-" + this.state.fechasConsultas);
    // console.log(e.target.checked);
    let valor = e.target.id;

    // this.setState({
    //   [e.target.name]: e.target.value,
    // });

    if (e.target.checked) {
      let cl = 0;
      let encontrado = 0;
      if (cl === 0) {
        encontrado = this.state.iinformeFechasS2.filter(
          (item) => item.variable4 === "P-" + this.state.fechasConsultas
        );
        if (encontrado.length > 0) cl = 1;
      }
      if (cl === 0) {
        encontrado = this.state.iinformeFechasS2.filter(
          (item) => item.variable5 === "P-" + this.state.fechasConsultas
        );
        if (encontrado.length > 0) cl = 1;
      }
      if (cl === 0) {
        encontrado = this.state.iinformeFechasS2.filter(
          (item) => item.variable6 === "P-" + this.state.fechasConsultas
        );
        if (encontrado.length > 0) cl = 1;
      }
      if (cl === 0) {
        encontrado = this.state.iinformeFechasS2.filter(
          (item) => item.variable7 === "P-" + this.state.fechasConsultas
        );
        if (encontrado.length > 0) cl = 1;
      }
      if (cl === 0) {
        encontrado = this.state.iinformeFechasS2.filter(
          (item) => item.variable8 === "P-" + this.state.fechasConsultas
        );
        if (encontrado.length > 0) cl = 1;
      }
      if (cl === 0) {
        encontrado = this.state.iinformeFechasS2.filter(
          (item) => item.variable9 === "P-" + this.state.fechasConsultas
        );
      }

      // console.log(this.state.iinformeFechasS2);
      // console.log('encontrado '+encontrado.length);
      if (encontrado.length === 0) {
        //console.log('paso encontrado '+encontrado.length);
        let ihorario_ = Array.from(this.state.iinformeFechasS2);
        if (valor === "4")
          ihorario_[index].variable4 = "P-" + this.state.fechasConsultas;
        if (valor === "5")
          ihorario_[index].variable5 = "P-" + this.state.fechasConsultas;
        if (valor === "6")
          ihorario_[index].variable6 = "P-" + this.state.fechasConsultas;
        if (valor === "7")
          ihorario_[index].variable7 = "P-" + this.state.fechasConsultas;
        if (valor === "8")
          ihorario_[index].variable8 = "P-" + this.state.fechasConsultas;
        if (valor === "9")
          ihorario_[index].variable9 = "P-" + this.state.fechasConsultas;
        this.setState({ iinformeFechasS2: ihorario_ });
      } else {
        e.target.checked = false;
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Remplazar Especialista.",
          cuerpoM:
            "Ya la Fecha esta Selecionada, Para Cambiarla debe Destildar la Opcion Seleccionada",
        });
      }
    }

    if (!e.target.checked) {
      let ihorario_ = Array.from(this.state.iinformeFechasS2);
      if (valor === "4") ihorario_[index].variable4 = "- ";
      if (valor === "5") ihorario_[index].variable5 = "- ";
      if (valor === "6") ihorario_[index].variable6 = "- ";
      if (valor === "7") ihorario_[index].variable7 = "- ";
      if (valor === "8") ihorario_[index].variable8 = "- ";
      if (valor === "9") ihorario_[index].variable9 = "- ";
      this.setState({ iinformeFechasS2: ihorario_ });
    }
  }

  consultar(e) {
    if (parseFloat(e) > 0) {
      axios
        .post(
          "https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo=" +
            e +
            "&cedula=&motivo=&fecha=&boton=Consultar"
        )
        //console.log('https://fehensa.com/consultorio/ingresaresperaPHP.php/?codigo='+e+'&cedula=&motivo=&fecha=&boton=Consultar')
        .then((res) => {
          if (res.data) {
            this.setState({
              //   codigo: res.data.variable1,
              //   cedula: res.data.variable2,
              //   nombre: res.data.variable3,
              //   motivo: res.data.variable4,
              //   ordendellegada: res.data.variable5,
            });
          }
        });
    }
  }

  imprimir = () => {
    let data = new FormData();
    data.append("arregloPaciente", JSON.stringify(this.state.iinformeFechasS));
    axios({
      method: "post",
      url:
        "https://institutobilinguelasnaciones.com/terapias/fpdf/reportes/reporteHorarioPaciente.php",
      responseType: "arraybuffer",
      data: data,
    }).then(function (response) {
      let blob = new Blob([response.data], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    });
  };

  buscarI = () => {
    if (
      this.state.trimestre.length > 0 &&
      // this.state.idmateria.length > 0 &&
      this.state.idseccion.length > 0
    ) {
      let data = new FormData();
      data.append("idseccion", this.state.idseccion);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("trimestre", this.state.trimestre);
      data.append("idmateria", this.state.idmateria);
      data.append("anoEscolar", this.state.anoEscolar);
      if (this.state.cedulaAlumno === "") {
        data.append("boton", "buscarInasistenciaG");
      } else {
        data.append("boton", "buscarInasistencia");
      }

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/alumnoMaestro.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          if (res.data.variable1) {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              xtotales: res.data.variable3,
            });
          } else {
            let xiinasis = res.data.variable2;
            this.setState({
              iinasistencia: xiinasis,
              mensajealerta: true,
              tituloM: "Menu Consultar Inasistencias.",
              cuerpoM: res.data.variable3,
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencias.",
        cuerpoM:
          "Debe seleccionar un Trimestre, un Alumno, un Grado y una Materia.",
      });
    }
  };

  eliminar = () => {
    if (this.state.idseccionA.length > 0) {
      let data = new FormData();
      data.append("idseccionesA", this.state.idseccionA);
      data.append("boton", "eliminarSeccionesA");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios.post(xurl, data, config).then((res) => {
        if (res.data.variable1) {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
          this.buscarSeccionesA(this.state.cedulaAlumno);
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Asignar Grados Alumnos.",
            cuerpoM: res.data.variable2,
          });
        }
      });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Grados Alumnos.",
        cuerpoM: "Debe existir un registro para poder eliminar la Sección.",
      });
    }
  };

  buscarSeccionesA = (e) => {
    if (this.state.anoEscolar.length > 0) {
      if (e.length > 0) {
        let data = new FormData();
        data.append("anoEscolar", this.state.anoEscolar);
        data.append("cedulaAlumno", e);
        data.append("boton", "buscarSeccionesA");

        let config = {
          headers: {
            "Content-Type": "text/html",
          },
        };
        const xurl =
          "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
        axios
          .post(xurl, data, config)

          .then((res) => {
            let xisecciones = res.data.variable3;
            let xiseccionesA = res.data.variable2;
            if (res.data.variable1) {
              this.setState({
                isecciones: xisecciones,
                iseccionesA: xiseccionesA,
              });
            } else {
              this.setState({
                iseccionesP: [],
              });
            }
          });
      } else {
        this.setState({
          mensajealerta: true,
          tituloM: "Menu Asignar Grados Alumnos.",
          cuerpoM: "Debe existir un Alumno para realizar el proceso.",
        });
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Asignar Seccion Alumnos.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSeccionesAG = () => {
    if (this.state.anoEscolar.length > 0) {
      let data = new FormData();
      data.append("boton", "buscarSeccionesAG");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          let xisecciones = res.data.variable2;
          if (res.data.variable1) {
            this.setState({
              isecciones: xisecciones,
            });
          } else {
            this.setState({
              isecciones: [],
            });
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Consultar Inasistencia.",
        cuerpoM: "Debe seleccionar un Año Escolar.",
      });
    }
  };

  buscarSecciones = () => {
    let data = new FormData();
    data.append("boton", "buscarSecciones");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/secciones.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiseccionesA = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iseccionesA: xiseccionesA,
          });
        } else {
          this.setState({
            iseccionesA: [],
          });
        }
      });
  };

  handleClose = () => {
    this.setState({ mensajealerta: false });
  };

  buscarAlumnos = (e) => {
    let form = new FormData();
    form.append("anoEscolar", this.state.anoEscolar);
    form.append("idcurso", e);
    form.append("boton", "buscarAlumnosG");
    let config = {
      headers: { "Content-Type": "text/html" },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/seccionesA.php";
    axios.post(xurl, form, config).then((res) => {
      let xialumnos = res.data.variable2;
      this.setState({
        ialumnos: xialumnos,
      });
    });
  };

  buscarAnioEscolar = () => {
    let data = new FormData();
    data.append("boton", "buscarAnioEscolar");
    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios.post(xurl, data, config).then((res) => {
      if (res.data.variable1) {
        this.setState({
          //anoEscolar: res.data.variable2,
          ianoEscolar: res.data.variable3,
        });
      }
    });
  };

  eliminar1 = (e) => {
    this.setState({
      mensajealerta: true,
      tituloM: "Menu Asignar Grados a Alumnos",
      cuerpoM:
        "Puede que el Grado Asignado ya tenga notas Asociadas, Desea Realmente Eliminar el Registro...",
      idseccionA: e,
      elimi: true,
    });
  };

  handleEliminar = () => {
    this.setState({
      mensajealerta: false,
      elimi: false,
    });
    this.eliminar();
    //console.log('eliminar')
  };

  buscarIT = (e) => {
    let data = new FormData();
    data.append("cedulaAlumno", e);
    data.append("boton", "consultarInforme");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
            iinformeFechasS: xiinformeFechasS,
            iinformeFechas: xiinformeFechas,
          });
        } else {
          this.setState({
            iinformeFechasS: [],
            iinformeFechas: [],
          });
        }
      });
  };

  buscarPlanillaIndividual = () => {
    if (this.state.identificacionp1.length > 0) {
      let data = new FormData();
      data.append("cedulaAlumno", this.state.identificacionp1);
      data.append("boton", "buscarPlanillaIndividual");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //console.log(res.data.variable133)
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  buscarPorNombres = () => {
    let data = new FormData();
    data.append("nombres", this.state.identificacionp1);
    data.append("boton", "buscarNombres");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        //console.log(res.data.variable3);
        //console.log(res.data.variable2);
        if (res.data.variable1) {
          let xnalumno = res.data.variable2;
          this.setState({
            nalumno: xnalumno,
            listaOn: true,
          });
        } else {
          this.setState({
            mensajealerta: true,
            tituloM: "Menu Busqueda de Planillas",
            cuerpoM: "No existe ningún Paciente con ese Nombre, gracias...",
          });
        }
      });
  };

  consulta = (e) => {
    if (e.length > 0) {
      let data = new FormData();
      data.append("idAlumno", e);
      data.append("boton", "buscarPlanillaIndividual1");
      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/maternal.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          console.log(res.data.variable133);
          if (res.data.variable136) {
            if (res.data.variable129 === "MADRE") {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            } else {
              this.setState({
                alumno: res.data.variable1 + " " + res.data.variable2,
                cedula: res.data.variable4,
                cedulaAlumno: res.data.variable4,
                listaOn: false,
              });
              this.buscarIT(res.data.variable4);
              // this.buscarCXC(res.data.variable4);
              // this.buscarNC();
            }
          } else {
            this.setState({
              mensajealerta: true,
              tituloM: "Menu Busqueda de Planillas",
              cuerpoM: "La cédula que intenta consultar no existe, gracias...",
            });
          }
        });
    }
  };

  cerrarLista = () => {
    //console.log(this.state.listaOn)
    if (this.state.listaOn) {
      this.setState({
        listaOn: false,
      });
    }
    if (!this.state.listaOn) {
      this.setState({
        listaOn: true,
      });
    }
  };

  buscarPlanilla = () => {
    if (
      this.state.tbusqueda === "1" ||
      this.state.tbusqueda === "2" ||
      this.state.tbusqueda === "3" ||
      this.state.tbusqueda === "4"
    ) {
      if (this.state.tbusqueda === "1") {
        this.buscarPlanillaIndividual();
      }
      if (this.state.tbusqueda === "2") {
        this.buscarPorNombres();
      }
      if (this.state.tbusqueda === "3") {
        this.setState({ grado: "0" });
        this.buscarCursosGrados();
        //this.buscarCursosGrados()
      }
      if (this.state.tbusqueda === "4") {
        this.buscarPorNombres3();
      }
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Busqueda de Planillas",
        cuerpoM:
          "Debe seleccionar un Año escolar y un tipo de busqueda, gracias...",
      });
    }
  };

  remplazarE = () => {
    if (this.state.remplazarOn) {
      this.setState({
        remplazarOn: false,
        imedicos: [],
        medicos: "",
        ifechasConsultas: [],
        fechasConsultas: "",
        iespecialista: [],
        especialista: "",
        iinformeFechasS2: [],
      });
    } else {
      this.setState({
        remplazarOn: true,
      });
      this.terapeutasAsociados();
    }
  };

  remplazarEH = () => {
    if (this.state.remplazarHOn) {
      this.setState({
        remplazarHOn: false,
        imedicos: [],
        medicos: "",
        ifechasConsultas: [],
        fechasConsultas: "",
        iespecialista: [],
        especialista: "",
        iinformeFechasS2: [],
      });
    } else {
      this.setState({
        remplazarHOn: true,
      });
      this.terapeutasAsociados();
    }
  };

  cambioHorario = () => {};

  terapeutasAsociados = () => {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("boton", "consultarTerapeutasAsociados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiespecialista = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            iespecialista: [],
            iespecialista: xiespecialista,
          });
        } else {
          this.setState({
            iespecialista: [],
          });
        }
      });
  };

  horarioTerapeutasAsociados = (e) => {
    let data = new FormData();
    data.append("cedulaAlumno", this.state.cedulaAlumno);
    data.append("cedulaEspecialista", e);
    data.append("boton", "horarioTerapeutasAsociados");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xifechasConsultas = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            fechasConsultas: "",
            ifechasConsultas: [],
            ifechasConsultas: xifechasConsultas,
          });
        } else {
          this.setState({
            fechasConsultas: "",
            ifechasConsultas: [],
          });
        }
      });
  };

  terapeutasDisponibles = () => {
    let data = new FormData();
    //    data.append("idcalendario", e);
    data.append("boton", "terapeutasDisponibles");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let ximedicos = res.data.variable2;
        if (res.data.variable1) {
          this.setState({
            medicos: "",
            imedicos: [],
            imedicos: ximedicos,
          });
        } else {
          this.setState({
            medicos: "",
            imedicos: [],
          });
        }
      });
  };

  buscarEspecialista = (e) => {
    let data = new FormData();
    data.append("cedulaTerapeuta", e);
    data.append("boton", "consultarInformeTerapeuta");

    let config = {
      headers: {
        "Content-Type": "text/html",
      },
    };
    const xurl =
      "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
    axios
      .post(xurl, data, config)

      .then((res) => {
        let xiinformeFechasS = res.data.variable2;
        //let xiinformeFechas = res.data.variable3;
        if (res.data.variable1) {
          this.setState({
            iinformeFechasS2: [],
            //iinformeFechas: [],
            iinformeFechasS2: xiinformeFechasS,
            //iinformeFechas: xiinformeFechas,
          });
        } else {
          this.setState({
            iinformeFechasS2: [],
            //iinformeFechas: [],
          });
        }
      });
  };

  guardarCalendario = () => {
    if (this.state.medicos.length > 0) {
      let data = new FormData();
      data.append("cedulaTerapeuta", this.state.medicos);
      data.append("tipoEstudio", "0");
      if (this.state.fechasConsultas.length > 0)
        data.append("tipoProceso", "S");
      if (this.state.fechasConsultas.length === 0)
        data.append("tipoProceso", "T");
      if (this.state.fechasConsultas.length === 0)
        data.append("arreglo", JSON.stringify(this.state.ifechasConsultas));
      if (this.state.fechasConsultas.length > 0)
        data.append("arreglo", JSON.stringify(this.state.iinformeFechasS2));
      data.append("boton", "procesar");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //let xiinformeFechasS = res.data.variable2;
          //let xiinformeFechas = res.data.variable3;
          if (res.data.variable1) {
            this.buscarIT(this.state.cedulaAlumno);
            this.terapeutasAsociados();
            this.setState({
              especialista: "",
              fechasConsultas: "",
              ifechasConsultas: [],
              medicos: "",
              medicos: [],
              iinformeFechasS2: [],
              mensajealerta: true,
              tituloM: "Menu Remplazar Horario",
              cuerpoM: "Se Realizo el Remplazo con Exito",
            });
          } else {
            if (res.data.variable2 !== "") {
              this.setState({
                //iinformeFechas: [],
                mensajealerta: true,
                tituloM: "Menu Remplazar Horario",
                cuerpoM:
                  "Debe Verificar los siguientes dia y horas, ya que no estan disponible en el especialista seleccionado " +
                  res.data.variable2,
              });
            }
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Remplazar Horario",
        cuerpoM: "Debe seleccionar un Especialista, gracias...",
      });
    }
  };

  guardarCalendarioH = () => {
    if (
      this.state.especialista.length > 0 &&
      this.state.fechasConsultas.length > 0
    ) {
      let data = new FormData();
      data.append("cedulaTerapeuta", this.state.especialista);
      data.append("cedulaAlumno", this.state.cedulaAlumno);
      data.append("tipoProceso", "S");
      data.append("arreglo", JSON.stringify(this.state.iinformeFechasS2));
      data.append("boton", "procesarH");

      let config = {
        headers: {
          "Content-Type": "text/html",
        },
      };
      const xurl =
        "https://institutobilinguelasnaciones.com/terapias/backend/consultaInformesTerapia.php";
      axios
        .post(xurl, data, config)

        .then((res) => {
          //let xiinformeFechasS = res.data.variable2;
          //let xiinformeFechas = res.data.variable3;
          if (res.data.variable1) {
            this.buscarIT(this.state.cedulaAlumno);
            this.terapeutasAsociados();
            if (res.data.variable2 !== "") {
              this.setState({
                especialista: "",
                fechasConsultas: "",
                ifechasConsultas: [],
                medicos: "",
                medicos: [],
                iinformeFechasS2: [],
                mensajealerta: true,
                tituloM: "Menu Remplazar Horario",
                cuerpoM:
                  "Debe Verificar los siguientes dia y horas, ya que en el Paciente no estan disponible " +
                  res.data.variable2,
              });
            }
            if (res.data.variable2 === "") {
              this.setState({
                especialista: "",
                fechasConsultas: "",
                ifechasConsultas: [],
                medicos: "",
                medicos: [],
                iinformeFechasS2: [],
                mensajealerta: true,
                tituloM: "Menu Cambio de Horario",
                cuerpoM: "Se Realizo el Cambio con Exito",
              });
            }
          } else {
            if (res.data.variable2 !== "") {
              this.setState({
                //iinformeFechas: [],
                mensajealerta: true,
                tituloM: "Menu Remplazar Horario",
                cuerpoM:
                  "Debe Verificar los siguientes dia y horas, ya que en el Paciente no estan disponible " +
                  res.data.variable2,
              });
            }
          }
        });
    } else {
      this.setState({
        mensajealerta: true,
        tituloM: "Menu Remplazar Horario",
        cuerpoM: "Debe seleccionar un Especialista y una Fecha, gracias...",
      });
    }
  };

  componentDidMount() {
    //this.buscarIT();
  }

  render() {
    return (
      <div className="principal-pago row">
        <div className="contenedor-cxc col-12">
          <div className="titulo-cxc ">
            <p>CONSULTA TERAPIAS X DIAS. </p>
          </div>
          {this.state.mensajealerta === true && (
            <Alert
              tituloMensaje={this.state.tituloM}
              cuerpoMensaje={this.state.cuerpoM}
              open={this.state.mensajealerta}
              handleClose={this.handleClose}
              eliminar={this.state.elimi}
              handleEliminar={this.handleEliminar}
            />
          )}
          {!this.state.verplanillaOn && (
            <div className="contenedorp row">
              <div className="titulo-planillai col-12">
                <p>
                  SELECCIONE TIPO DE BUSQUEDA E INTRODUZCA LOS DATOS
                  CORRESPONDIENTA A SU SELECCIÓN.{" "}
                </p>
              </div>
              <div className="row">
                <div className="fechas col-12">
                  <select
                    id="tbusqueda"
                    name="tbusqueda"
                    value={this.state.tbusqueda}
                    onChange={this.onChange.bind(this)}
                  >
                    <option>-- Buscar por --</option>
                    <option value="1">CEDULA/PASAPORTE</option>
                    <option value="2">NOMBRE PACIENTE</option>
                    {/* <option value="3">CURSO/GRADO</option>
                    <option value="4">TODOS LOS PACIENTES</option> */}
                  </select>
                </div>
              </div>
              {this.state.tbusqueda === "3" && (
                <div className="row">
                  <div className="fechas col-12">
                    <select
                      id="grado"
                      name="grado"
                      value={this.state.grado}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-- Seleccione Grado --</option>
                      {this.state.igrados.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}
              {this.state.tbusqueda < "3" && (
                <div className="contenedorcedula1 row">
                  <input
                    className="form-control"
                    type="text"
                    name="identificacionp1"
                    id="identificacionp1"
                    autoComplete="off"
                    placeholder={
                      this.state.tbusqueda === "1"
                        ? "Identificación Paciente."
                        : "Nombre Paciente."
                    }
                    maxLength="300"
                    onChange={this.onChange.bind(this)}
                    value={this.state.identificacionp1.toUpperCase()}
                  />
                </div>
              )}
              <div className="xcontenedorcedula1 col-12">
                <button
                  className="boton"
                  onClick={this.buscarPlanilla.bind(this)}
                >
                  Consultar
                </button>
                <button className="boton" onClick={this.limpiar.bind(this)}>
                  Otra Consulta
                </button>
                <button
                  className={
                    this.state.iinformeFechasS.length > 0 ? "boton" : "botonOf"
                  }
                  onClick={
                    this.state.iinformeFechasS.length > 0
                      ? this.imprimir.bind(this)
                      : ""
                  }
                >
                  Imprimir Horario
                </button>
                {this.state.iinformeFechasS.length > 0 && (
                  <button
                    className="boton"
                    onClick={this.remplazarE.bind(this)}
                  >
                    Remplazar Especialista
                  </button>
                )}
                {this.state.iinformeFechasS.length > 0 && (
                  <button
                    className="boton"
                    onClick={this.remplazarEH.bind(this)}
                  >
                    Cambio Horario
                  </button>
                )}
                <Link to="/">
                  <button className="boton">Salir</button>
                </Link>
              </div>

              {this.state.listaOn && (
                <div className="cont-tablamedi row">
                  <div className="tablamedi1 col-11">
                    <div className="cont-titulomedi col-12">
                      <span className="titulocasos">Listado de Pacientes</span>
                      <span
                        className="titulo-cerrarcasos"
                        onClick={this.cerrarLista.bind(this)}
                      >
                        X
                      </span>
                    </div>
                    {/* <span className="titulo-casos">Datos del Pago</span> */}
                    <div className="cont-listmedi row">
                      <div className="listmedi col-12">
                        <table className="tablame" border="1">
                          <tbody className="evocuerpo1">
                            {this.state.nalumno.map((item, i) => {
                              return (
                                <tr
                                  className={i % 2 === 0 ? "odd" : ""}
                                  key={item.variable1}
                                  id="tabla"
                                >
                                  <td
                                    className="renglon"
                                    width="10"
                                    onClick={this.consulta.bind(
                                      this,
                                      item.variable1
                                    )}
                                  >
                                    {item.variable2}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {this.state.remplazarOn && (
            <div className="cont-tablamedir row">
              <div className="tablamedi1r col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Menu para reemplazar Especialista
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.remplazarE.bind(this)}
                  >
                    X
                  </span>
                </div>

                <div className="salto">{""}</div>

                <div className="contenedoasociar row">
                  <div className="contenedor-cedula col-3">
                    <select
                      id="especialista"
                      name="especialista"
                      value={this.state.especialista}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Especialista a Remplazar-</option>
                      {this.state.iespecialista.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedor-cedula col-3">
                    <select
                      id="fechasConsultas"
                      name="fechasConsultas"
                      value={this.state.fechasConsultas}
                      onChange={this.onChange.bind(this)}
                    >
                      <option value="">-Horario a Remplazar-</option>
                      {this.state.ifechasConsultas.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable3}
                            {" - "}
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedor-cedula col-3">
                    <select
                      id="medicos"
                      name="medicos"
                      value={this.state.medicos}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Especialista a Nuevo-</option>
                      {this.state.imedicos.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {!this.state.verplanillaOn && (
                  <div className="row">
                    {this.state.iinformeFechasS2.map((item, i) => {
                      return (
                        <div className="cont-diasterapia">
                          {item.variable11 === "1" && (
                            <div className="cont-diasterapia3 row">
                              <div className="diasH">HORARIO</div>
                              <div className="diasS">LUNES</div>
                              <div className="diasS">MARTES</div>
                              <div className="diasS">MIERCOLES</div>
                              <div className="diasS">JUEVES</div>
                              <div className="diasS">VIERNES</div>
                              <div className="diasS">SABADO</div>
                            </div>
                          )}
                          {item.variable11 !== "1" && (
                            <div className="group row">
                              <div className="XdiasH"> {item.variable3}</div>
                              <div className="XdiasS">
                                {item.variable4 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={4}
                                    value={item.variable4}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable4.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={4}
                                    //value={item.variable4}
                                    checked={
                                      item.variable4.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable4}
                              </div>
                              <div className="XdiasS">
                                {item.variable5 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={5}
                                    value={item.variable5}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable5.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={5}
                                    //value={item.variable4}
                                    checked={
                                      item.variable5.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable5}
                              </div>
                              <div className="XdiasS">
                                {item.variable6 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={6}
                                    value={item.variable6}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable6.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={6}
                                    //value={item.variable4}
                                    checked={
                                      item.variable6.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable6}
                              </div>
                              <div className="XdiasS">
                                {item.variable7 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={7}
                                    value={item.variable7}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable7.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={7}
                                    //value={item.variable4}
                                    checked={
                                      item.variable7.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable7}
                              </div>
                              <div className="XdiasS">
                                {item.variable8 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={8}
                                    value={item.variable8}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable8.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={8}
                                    //value={item.variable4}
                                    checked={
                                      item.variable8.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable8}
                              </div>
                              <div className="XdiasS">
                                {item.variable9 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={9}
                                    value={item.variable9}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable9.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={9}
                                    //value={item.variable4}
                                    checked={
                                      item.variable9.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable9}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}

                {!this.state.tieneHorario && (
                  <div className="cont-listmedir row">
                    <div className="listmedir col-12">
                      <div className="salto">
                        <button
                          className="boton"
                          onClick={this.guardarCalendario.bind(this)}
                        >
                          Procesar
                        </button>

                        <button
                          className="boton"
                          onClick={this.remplazarEH.bind(this)}
                        >
                          Salir
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {this.state.remplazarHOn && (
            <div className="cont-tablamedir row">
              <div className="tablamedi1r col-11">
                <div className="cont-titulomedi col-12">
                  <span className="titulocasos">
                    Menu para Cambiar Horario Especialista
                  </span>
                  <span
                    className="titulo-cerrarcasos"
                    onClick={this.remplazarEH.bind(this)}
                  >
                    X
                  </span>
                </div>

                <div className="salto">{""}</div>

                <div className="contenedoasociar row">
                  <div className="contenedor-cedula col-3">
                    <select
                      id="especialista"
                      name="especialista"
                      value={this.state.especialista}
                      onChange={this.onChange.bind(this)}
                    >
                      <option>-Especialista a Remplazar-</option>
                      {this.state.iespecialista.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="contenedor-cedula col-3">
                    <select
                      id="fechasConsultas"
                      name="fechasConsultas"
                      value={this.state.fechasConsultas}
                      onChange={this.onChange5.bind(this)}
                    >
                      <option value="">-Horario a Remplazar-</option>
                      {this.state.ifechasConsultas.map((item, i) => {
                        return (
                          <option key={i} value={item.variable1}>
                            {item.variable3}
                            {" - "}
                            {item.variable2}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {!this.state.verplanillaOn && (
                  <div className="row">
                    {this.state.iinformeFechasS2.map((item, i) => {
                      return (
                        <div className="cont-diasterapia">
                          {item.variable11 === "1" && (
                            <div className="cont-diasterapia3 row">
                              <div className="diasH">HORARIO</div>
                              <div className="diasS">LUNES</div>
                              <div className="diasS">MARTES</div>
                              <div className="diasS">MIERCOLES</div>
                              <div className="diasS">JUEVES</div>
                              <div className="diasS">VIERNES</div>
                              <div className="diasS">SABADO</div>
                            </div>
                          )}
                          {item.variable11 !== "1" && (
                            <div className="group row">
                              <div className="XdiasH"> {item.variable3}</div>
                              <div className="XdiasS">
                                {item.variable4 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={4}
                                    value={item.variable4}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable4.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={4}
                                    //value={item.variable4}
                                    checked={
                                      item.variable4.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable4}
                              </div>
                              <div className="XdiasS">
                                {item.variable5 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={5}
                                    value={item.variable5}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable5.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={5}
                                    //value={item.variable4}
                                    checked={
                                      item.variable5.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable5}
                              </div>
                              <div className="XdiasS">
                                {item.variable6 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={6}
                                    value={item.variable6}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable6.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={6}
                                    //value={item.variable4}
                                    checked={
                                      item.variable6.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable6}
                              </div>
                              <div className="XdiasS">
                                {item.variable7 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={7}
                                    value={item.variable7}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable7.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={7}
                                    //value={item.variable4}
                                    checked={
                                      item.variable7.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable7}
                              </div>
                              <div className="XdiasS">
                                {item.variable8 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={8}
                                    value={item.variable8}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable8.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={8}
                                    //value={item.variable4}
                                    checked={
                                      item.variable8.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable8}
                              </div>
                              <div className="XdiasS">
                                {item.variable9 === "- " && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={9}
                                    value={item.variable9}
                                    //checked={item.variable4 === "1"}
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable9.substr(0, 2) === "P-" && (
                                  <input
                                    type="checkbox"
                                    className="opvacunacionsi"
                                    name={i}
                                    id={9}
                                    //value={item.variable4}
                                    checked={
                                      item.variable9.substr(0, 2) === "P-"
                                    }
                                    onChange={this.onChange4.bind(this)}
                                  />
                                )}
                                {item.variable9}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}

                {!this.state.tieneHorario && (
                  <div className="cont-listmedir row">
                    <div className="listmedir col-12">
                      <div className="salto">
                        <button
                          className="boton"
                          onClick={this.guardarCalendarioH.bind(this)}
                        >
                          Procesar
                        </button>

                        <button
                          className="boton"
                          onClick={this.remplazarEH.bind(this)}
                        >
                          Salir
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {!this.state.verplanillaOn && (
            <div className="row">
              {this.state.iinformeFechasS.map((item, i) => {
                return (
                  <div className="cont-diasterapia">
                    {item.variable11 === "0" && (
                      <div className="cont-diasterapia2 row">
                        <div className="diasNombre col-12">
                          PACIENTE: {item.variable2}
                        </div>
                        <div className="diasHa">HORARIO</div>
                        <div className="diasS">LUNES</div>
                        <div className="diasS">MARTES</div>
                        <div className="diasS">MIERCOLES</div>
                        <div className="diasS">JUEVES</div>
                        <div className="diasS">VIERNES</div>
                        <div className="diasS">SABADO</div>
                        <div className="diasS">DOMINGO</div>
                      </div>
                    )}
                    {item.variable11 !== "1" && (
                      <div className="group row">
                        <div className="XdiasH">{item.variable3}</div>
                        <div className="XdiasS">{item.variable4}</div>
                        <div className="XdiasS">{item.variable5}</div>
                        <div className="XdiasS">{item.variable6}</div>
                        <div className="XdiasS">{item.variable7}</div>
                        <div className="XdiasS">{item.variable8}</div>
                        <div className="XdiasS">{item.variable9}</div>
                        <div className="XdiasS">{item.variable10}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default SeccionesAAction;
